<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>用户追踪</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav"> 
        <div style="margin-left: 10px"></div>
      </div>
    </div>
    <div class="content-main">
      <!-- <div class="filter-box">
        <el-form :inline="true" :model="filterForm">
          <el-form-item label="用户昵称">
            <el-input v-model="filterForm.nickname" placeholder="用户昵称"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名">
            <el-input v-model="filterForm.name" placeholder="用户姓名"></el-input>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="filterForm.company_name" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item label="跟进记录">
            <el-input v-model="filterForm.remark" placeholder="跟进记录"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="resetFilter">重置</el-button>
            <el-button type="primary" @click="onSubmitFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="form-table-box" v-if="fake == 0">
        <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="timeText" label="时间" width="100">
            </el-table-column>
            <el-table-column label="头像" width="80">
              <template scope="scope">
                <img
                  :src="scope.row.avatar"
                  v-if="scope.row.avatar"
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称"> </el-table-column>
            <el-table-column prop="mobile" label="手机号"> </el-table-column>
            <el-table-column prop="type" label="动态" width="110">
              <template scope="scope">
                <el-button
                  v-if="scope.row.type == 'footprint'"
                  type="primary"
                  size="small"
                  >浏览产品</el-button
                >
                <el-button
                  v-if="scope.row.type == '1'"
                  type="success"
                  size="small"
                  >查看主页</el-button
                >
                 <el-button
                  v-if="scope.row.type == '2'"
                  type="danger"
                  size="small"
                  >拨打电话</el-button
                >
                 <el-button
                  v-if="scope.row.type == '3'"
                  type="warning"
                  size="small"
                  >查看微信</el-button
                >
              </template>
            </el-table-column>

            <!-- <el-table-column prop="keyword" label="搜索关键词">
              <template scope="scope">
                <el-tag type="success" v-if="scope.row.keyword">{{
                  scope.row.keyword
                }}</el-tag>
              </template>
            </el-table-column> -->
<el-table-column prop="duration" label="停留时长(s)">
            </el-table-column>

            <el-table-column label="商品" width="80">
              <template scope="scope">
                <img
                  :src="scope.row.list_pic_url + '=thumb'"
                  v-if="scope.row.list_pic_url"
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="goods_name" label="商品名称">
            </el-table-column>

        </el-table>
      </div>
      <div class="page-box" v-if="fake == 0">
        <el-pagination
          background
          @current-change="handlePageChange"
          :current-page.sync="page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDynamicPage',
  data() {
    return {
      page: '',
      total: 0,
      filterForm: {
        nickname: '',
        company_name: '',
        mobile: '',
        remark: ''
      },
      tableData: [],
      fakeData: [],
      fake: 0,
      loginInfo: null,
      username: ''
    };
  },
  methods: {
    unLockMsg(index,row){
        let id = row.goods_id
        
    },
    
    fakeShow() {
      this.fake = 1;
    },
    realShow() {
      this.fake = 0;
    },
    submitNick(index, row) {
      this.axios
        .post('user/updateInfo', { id: row.id, nickname: row.nickname })
        .then((response) => {});
    },
    handlePageChange(val) {
      this.page = val;
      //保存到localStorage
      // localStorage.setItem('userDynamicPage', this.page);
      this.getList();
    },

    
    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    resetFilter() {
      this.filterForm = {
        nickname: '',
        company_name: '',
        mobile: '',
        remark: ''
      };
      this.onSubmitFilter();
    },
    getList() {
      this.axios
        .get('dynamic/dynamicList', {
          params: {
            page: this.page,
            type:3,
          }
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = parseInt(response.data.data.currentPage);
          this.total = response.data.data.count;

          // this.$set(this, 'page', response.data.data.currentPage);
        });
      if (!this.loginInfo) {
        this.loginInfo = JSON.parse(window.localStorage.getItem('userInfo') || null);
        this.username = this.loginInfo.username;
      }
    }
  },
  components: {},
  mounted() {
    let thePage = 1;
    this.page = Number(thePage);
    this.getList();
  }
};
</script>

<style scoped></style>
